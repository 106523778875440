import React from 'react';

const IconLogo = () => (
  <svg
    width="394"
    height="391"
    viewBox="0 0 394 391"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M128.484 264C124.422 264 123.367 261.422 125.32 256.266L183.68 103.102C185.477 98.3359 188.055 95.9531 191.414 95.9531H207.82C211.18 95.9531 213.758 98.3359 215.555 103.102L273.914 256.266C275.867 261.422 274.812 264 270.75 264H258.797C254.656 264 251.844 262.086 250.359 258.258L235.711 219.469H163.523L148.875 258.258C147.391 262.086 144.578 264 140.438 264H128.484ZM170.906 200.25H228.328L210.398 153.375C208.758 149 207.117 144.156 205.477 138.844C203.914 133.531 202.039 125.992 199.852 116.227H199.383C197.195 125.992 195.281 133.531 193.641 138.844C192.078 144.156 190.477 149 188.836 153.375L170.906 200.25Z"
      fill="currentColor"
    />
    <path
      d="M38.893 104.76L197 14.3975L355.107 104.76V285.265L197 375.628L38.893 285.266V104.76Z"
      stroke="currentColor"
      strokeWidth="25"
    />
  </svg>
);

export default IconLogo;
